export default {
    methods: {
        getGynoceShutters(props) {
            return {
                'horse-gyneco': {
                    name: 'horse-gyneco',
                    title: '',
                    onOk: this.onShuttersSubmit,
                    cmpPath: 'components/Gynecologie/Horse',
                    props
                },
                'gyneco-historique': {
                    name: 'gyneco-historique',
                    title: this.$t('gynecologie.historique'),
                    cmpPath: 'components/Gynecologie/HorseHistoriqueSaisonEtalon',
                    props
                },
				'gyneco-embryons': {
                    name: 'gyneco-embryons',
                    title: this.$t('gynecologie.embryons'),
                    cmpPath: 'components/Gynecologie/EmbryonSeason',
                    props
                },
                'info-contacts': {
                    name: 'info-contacts',
                    title: this.$t('gynecologie.info_contact.title'),
                    cmpPath: 'components/Gynecologie/InfoContacts',
                    props
                },
                'stat-saillies': {
                    name: 'stat-saillies',
                    title: this.$t('monte.stat_saillies'),
                    cmpPath: 'components/Monte/StatSaillies',
                    props
                }
            }
        },

        setupHorseGyneco(horse, need_show_horsename=false) {
            const shutters = this.getGynoceShutters({
                horse,
                need_show_horsename
            })

            shutters['horse-gyneco'].title = horse.horse_nom
            this.shutterPanel().open(shutters['horse-gyneco'])
        },

        setupHorseGynecoHistory(season_id, horse_id, goToAddHorseStallion=false) {
            const shutters = this.getGynoceShutters({
                season_id,
                horse_id,
                goToAddHorseStallion
            })

            this.shutterPanel().open(shutters['gyneco-historique'])
        },

        setupHorseSanitaire(seasonmare_id, season_id) {
            const shutters = this.getGynoceShutters({
                seasonmare_id,
                season_id
            })

            this.shutterPanel().open(shutters['horse-sanitaire'])
        },

		setupHorseGynecoEmbryons(season_id, horse_id) {
            const shutters = this.getGynoceShutters({
                season_id,
                horse_id
            })

            this.shutterPanel().open(shutters['gyneco-embryons'])
        },

        setupHorseInfoContact(horse_id) {
            const shutters = this.getGynoceShutters({
                horse_id
            })

            this.shutterPanel().open(shutters['info-contacts'])
        },

        setupStatSaillies(horse_id) {
            const shutters = this.getGynoceShutters({
                horse_id
            })

            this.shutterPanel().open(shutters['stat-saillies'])
        },
    }
}
