<template>
	<div>
		<div class="box">
			<div class="row">
				<div class="mr-auto ml-3 mb-3">
					<e-select
						v-model="residence_selected"
		                :options="residences"
		                :searchable="true"
		                label="lieu_label"
		                :placeholder="$t('monte.choix_lieu')"
	                	:loading="loadingLieu"
					>	
						<template slot="singleLabel" slot-scope="{ option }">{{ option.lieu_label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
				</div>
				<div v-if="planning.planning_id" class="ml-auto mr-3 mb-3">
					<button class="btn btn-primary mr-3" @click="add">{{ $t('monte.create_jument') }}</button>
					<button class="btn btn-secondary mr-3" @click="openModalSaillie">{{ $t('monte.saillie_lot') }}</button>
					<button class="btn btn-secondary mr-3" @click="printToday"><font-awesome-icon v-if="print_processing" :icon="['fas', 'spinner']" pulse />{{ $t('monte.imprimer_planning') }}</button>
					<button class="btn btn-secondary" @click="openModalPrint">{{ $t('monte.imprimer_planning_period') }}</button>
					<button v-if="planning.planning_horse" class="btn btn-secondary ml-3" @click="openModalSelectHorse">{{ $t('horse.print_infos_horse') }}</button>
					<button class="btn btn-secondary ml-3" @click="openModalCourrierFeuilleSaillie"><font-awesome-icon v-if="print_processing_feuille" :icon="['fas', 'spinner']" pulse />{{ $t('monte.imprimer_feuille_saillie') }}</button>
				</div>
			</div>
			<div class="row">
				<div class="col-12 my-3 d-flex justify-content-around align-items-center">
					<textarea class="form-control" id="planning_commentaire" :placeholder="$t('horse.commentaire')" v-model="commentaire"></textarea>
					<button @click.prevent="saveComment" class="btn btn-primary col-1 ml-3" ><font-awesome-icon v-if="comment_processing" :icon="['fas', 'spinner']" pulse /><font-awesome-icon v-else :icon="['fal', 'save']" class="ml-1" /></button>
				</div>
			</div>
			<div class="row">
				<div class="col-12 d-flex justify-content-between align-items-center">
					<button class="btn btn-secondary" @click="prev"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></button>
					<div class="d-flex">
						<h3 class="mr-2" style="line-height: 35px;">{{ date_label }}</h3>
						<e-datepicker class="d-inline" v-model="date" id="date" @input="change_date"></e-datepicker>
						<button class="btn btn-sm mx-2" v-bind:class="isToday" @click="change_date_today" >{{$t('global.aujourdhui')}}</button>
						<button class="btn btn-primary btn-sm mx-2 px-3" @click="change_date" ><font-awesome-icon :icon="['fal', 'sync']"/></button>
					</div>
					
					<button class="btn btn-secondary" @click="next"><font-awesome-icon :icon="['fal', 'long-arrow-right']" /></button>
				</div>
			</div>
		</div>
		<template v-if="season.length != 0 && residence_selected.lieu_id && planning.planning_id && !planningLoaded">
			<div class="box p-3" v-for="(schedule, index) in planning.planning_schedule" :key="index">
				<div class="table-responsive table-calendrier m-0">
					<table class="table table-variante table-monteIA">
						<tr>
							<td rowspan="100%" class="align-middle border-top-0 border-right">
								<b><router-link :to="{ name: 'horseFiche', params: { horse_id: schedule.stallion.horse_id }}">{{ schedule.stallion.horse_nom }}</router-link> | <a href="#" @click.prevent="openStallionStat(schedule.stallion.horse_id)"><font-awesome-icon :icon="['far', 'chart-area']" /></a></b> <br>
								<template v-if="schedule.nb_dose == 1">
									{{ schedule.nb_dose }} {{ $t('monte.dose_preparer') }}
								</template>
								<template v-else-if="schedule.nb_dose > 1">
									{{ schedule.nb_dose }} {{ $t('monte.doses_preparer') }}
								</template>
							</td>
							<td rowspan="100%" class="align-middle border-top-0 border-right text-md-center">
								{{ schedule.schedule.schedule_start }} - {{ schedule.schedule.schedule_end }}
							</td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.jument') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.horse_transpondeur') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('gynecologie.mare_status') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.contact') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.phone') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.contrat') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.sanitaire') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.actions') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.commentaire') }}</b></td>
							<td class="border-top-0 d-none d-md-table-cell"><b>{{ $t('monte.horse_lieustationnement') }}</b></td>
						</tr>
						<CellCalendarIABeta
							v-for="(schedule_info, index_2) in schedule.infos"
							:key="index_2"
							:schedule="schedule.schedule.schedule_id"
							:params="schedule_info"
							:edit_schedule.sync="edit_schedule"
							:delete_schedule.sync="delete_schedule"
						/>
					</table>
				</div>
			</div>
		</template>
		<template v-else-if="!planning.planning_id && planningLoaded">
			<LoadingSpinner class="col-12" />
		</template>
		<div class="box" v-else>
			<div class="alert alert-warning mt-5">{{ $t('monte.no_planning') }}</div>
		</div>

		<ModalConfirm 
			ref="modal_delete_schedule" 
			:icon="['fal', 'trash-alt']"
			:text_title="$t('monte.modal_title')"
			:text_question="$t('monte.modal_question')"
			:text_message="$t('modal.general.avertissement_suppression')"
			:text_button_ok="$t('action.supprimer')"
			:text_button_cancel="$t('global.annuler')"
			:callback_validation="deleteSchedule"
			:callback_cancel="closeModal" />

		<ModalPrintBeta 
			v-if="season.length != 0"
			ref="modal_print"
			type="IA"
			:date="date" 
			:lieu_id="residence_selected.lieu_id"
			:season_id="season[0].season_id"
		/>

		<ModalAddSaillieBeta
			v-if="season.length != 0 && planning.planning_id"
			ref="modal_saillie"
			:date="date" 
			:planning="planning"
			:season_id="season[0].season_id"
			:residence="residence_selected"
		/>
		<ModalSelectHorseBeta ref="modal_select_horse" />
		<ModalCourrierFeuilleSaillie ref="modal_courrier_feuille_saillie"/>
	</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	import Navigation from "@/mixins/Navigation.js";
    import PlanningMonte from "@/mixins/PlanningMonte.js"
    import Lieux from "@/mixins/Lieux.js"
	import ShutterPanel from 'GroomyRoot/components/Utils/ShutterPanel'
	import Shutter from '@/mixins/Shutter'
	import Gynecologie from '@/mixins/Gynecologie'
	import MonteShutters from '@/mixins/shutters-manager/Monte'
	import ShutterGyneco from "@/mixins/shutters-manager/Gyneco.js"

	export default {
		name: 'CalendarMonteIABeta',
		mixins: [Navigation, PlanningMonte, Shutter, MonteShutters, Lieux, Gynecologie, ShutterGyneco],
		props: {
			planning_date: { type: String, default: null }
		},
		data () {
			return {
				commentaire: "",
				comment_processing: false,
				date: new Date(),
				date_label: '',
				planning: {},
				season: [],
				edit_schedule: null,
				delete_schedule: null,
				schedulehorse_id: null, // créneau que l'on souhaite supprimer dans la modal

				residences: [],
				residence_selected: {},

				loadingLieu: false,
				planningLoaded: false,
				print_processing: false,
				print_processing_feuille: false,

				events_tab: {
					'PlanningMonte::refresh': this.reloadPlanning
				},
			}
		},

		mounted() {
			this.init_component()
		},
		computed :{
			isToday:function() {
				let today = new Date
				if(this.date.getDate()==today.getDate() && this.date.getMonth()==today.getMonth() && this.date.getFullYear()==today.getFullYear()){
					return "btn-primary"
				}
				return "btn-outline-primary"
			}
		},
		methods: {
			async init_component() {
				this.date = this.planning_date ? new Date(this.planning_date) : new Date();

				const residence = this.getConfig('lastResidencePlanningIA')
				this.season = await this.getCurrentSeason(this.date)
				if(residence) {
					this.residence_selected = residence
				}
				
				this.formatDate()
				this.loadResidence()
			},
			formatDate() {
				this.date_label = this.getTrad("monte.jours."+this.date.getDay())
			},

			async loadResidence() {
				this.loadingLieu = true

				if(this.season.length === 0) {
					this.loadingLieu = false
					return
				}

				this.residences = await this.getResidences()
				const residence = await this.getResidencePlanningDefault(this.season[0].season_id, "IA")

				if(residence && Object.entries(this.residence_selected).length == 0) {
					this.residence_selected = this.residences.find(lieu => lieu.lieu_id == residence.planning_lieu)
				}

				this.loadingLieu = false
			},

			async saveComment() {
				this.comment_processing = true
				await this.savePlanningComment(this.commentaire, this.residence_selected.lieu_id, this.date)
				this.comment_processing = false
			},

			async loadPlanning() {
				this.season = await this.getCurrentSeason(this.date)
				this.planningLoaded = true
				this.planning = {}
				this.commentaire = ''
				if(this.season.length != 0) {
					const planning = await this.getPlanningOnline(this.season[0].season_id, "IA", this.date, this.residence_selected.lieu_id)
					this.setConfig('lastResidencePlanningIA', this.residence_selected)
					if(planning.date == this.date.toDateInputValue()) {
						this.planning = planning
						this.planningLoaded = false
					}
				}
				const commentaire = await this.getPlanningComment(this.residence_selected.lieu_id, this.date)
				if( commentaire != null) this.commentaire = commentaire.planningcommentaire_commentaire
			},

			async prev() {
				let date = new Date(this.date.getTime())
				date.setDate(date.getDate() - 1)
				this.date = date
				this.formatDate()
				this.loadPlanning()
			},

			async next() {
				let date = new Date(this.date.getTime())
				date.setDate(date.getDate() + 1)
				this.date = date
				this.formatDate()
				this.loadPlanning()
			},

			add() {
				this.shutterPanel().keepGiven('monte-ia-calendar-beta')

				const shutter = this.monteShutters['monte-ia-ajout-beta']
				shutter.props.planning_id = this.planning.planning_id
				shutter.props.date_preselected = this.date
				shutter.props.season_id = this.season[0].season_id

				shutter.props.schedule_info = null
				shutter.props.schedule_id = null
				shutter.props.horse_id = null

				shutter.onOk = () => {
					this.loadPlanning()
				}

				this.shutterPanel().open(shutter)
			},

			async deleteSchedule() {
				await this.deleteScheduleOnline('IA', this.schedulehorse_id)
				await this.$sync.force(true, true)
				this.closeModal()
				this.loadPlanning()
			},

			closeModal() {
				this.schedulehorse_id = null
				this.$refs.modal_delete_schedule.closeModal()
			},

			openModalPrint() {
				this.$refs.modal_print.openModal()
			},

			openModalSaillie() {
				this.$refs.modal_saillie.openModal()
			},

			change_date() {
				this.formatDate()
				this.loadResidence()
				this.loadPlanning()
			},
			change_date_today() {
				this.date = new Date()
				this.change_date()
				this.loadPlanning()
			},

			openModalSelectHorse() {
				this.$refs.modal_select_horse.openModal(this.planning)
			},

			async printToday() {
                this.print_processing = true
                await this.printPlanningDay(this.season[0].season_id, 'IA', this.residence_selected.lieu_id, this.date.toDateInputValue())
                this.print_processing = false
			},

			async reloadPlanning() {
				this.planningLoaded = true
				this.planning = {}
				await this.$sync.force()
				this.loadPlanning()
			},

			openStallionStat(horse_id) {
				this.setupStatSaillies(horse_id)
			},

			openModalCourrierFeuilleSaillie() {
				this.print_processing_feuille = true
				const params = {
					season_id: this.season[0].season_id,
					type: 'IA',
					lieu_id: this.residence_selected.lieu_id,
					date: this.date.toDateInputValue(),
					schedule: this.planning.planning_schedule
				}

				this.$refs.modal_courrier_feuille_saillie.openModal(params)
				this.print_processing_feuille = false
			},
		},

		watch: {
			edit_schedule (obj) {
				if(!obj) return false

                this.shutterPanel().keepGiven('monte-ia-calendar-beta')

				const shutter = this.monteShutters['monte-ia-ajout-beta']
				shutter.props.schedule_info = obj.schedule_info
				shutter.props.schedule_id = obj.schedule_id
				shutter.props.horse_id = obj.horse_id
				shutter.props.season_id = this.season[0].season_id
				shutter.props.planning_id = this.planning.planning_id

				shutter.onOk = () => {
					this.loadPlanning()
				}

				this.shutterPanel().open(shutter)
			},

			delete_schedule (schedulehorse_id) {
				this.schedulehorse_id = schedulehorse_id.schedulehorse_id
				this.$refs.modal_delete_schedule.openModal()
			},

			residence_selected() {
				this.loadPlanning()
			}
		},

		components: {
			CellCalendarIABeta : () => import('@/components/Monte/CellCalendarIABeta'),
			ModalConfirm: () => import('GroomyRoot/components/Modals/ModalConfirm'),
			ModalPrintBeta : () => import('@/components/Monte/ModalPrintBeta'),
			ModalAddSaillieBeta: () => import('@/components/Monte/ModalAddSaillieBeta'),
			ModalSelectHorseBeta : () => import('@/components/Monte/ModalSelectHorseBeta'),
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ModalCourrierFeuilleSaillie: () => import('@/components/Modals/ModalCourrierFeuilleSaillie')
		}
	}

</script>